import React, { useState, useEffect } from 'react';  
import FlexibleAudioPlayer from './FlexibleAudioPlayer';
import SignInModal from './SignInModal';
import '../styles/HomePageV4.css';
import '../styles/FlexibleAudioPlayerV2.css';

const HomePage = () => {
  const [audioFiles, setAudioFiles] = useState([{
    audioUrl: "https://storage-audio-file.s3.amazonaws.com/audio/What-is-biglio.mp3",
    topic: "What is Biglio?",
    category: "Business", 
    username: "biglio",
    summary: "Biglio is an innovative audio platform that allows users to share knowledge, stories, and insights through short-form audio content."
  }]);

  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchAudioFiles = async () => {
    try {
      const fetchedFiles = [{
        audioUrl: "https://storage-audio-file.s3.amazonaws.com/audio/What-is-biglio.mp3",
        topic: "What is Biglio?",
        category: "Business",
        username: "biglio", 
        summary: "Biglio is an innovative audio platform that allows users to share knowledge, stories, and insights through short-form audio content."
      }];
      setAudioFiles(fetchedFiles);
    } catch (err) {
      console.error('Error fetching audio files:', err);
      setError('Failed to load audio files. Please try again later.');
    }
  };

  useEffect(() => {
    fetchAudioFiles();
  }, []);

  const openSignUpModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="home-container">
      <main>
        <section className="hero-section">
          <div className="content-wrapper">
            <div className="left-section">
              <h1 id="main-heading" className="tagline">
                Where Ideas Find<br />
                Their Voice
              </h1>
              <button 
                className="cta-button" 
                onClick={openSignUpModal}
                aria-haspopup="dialog"
              >
                Get started now
              </button>
              <article className="hero-content">
                <p className="intro-text">
                  Transform your insights into polished audio stories in seconds. Share your expertise, and let our AI voices bring your content to life with professional flair.
                </p>
                <nav aria-label="Features navigation">
                  <ul className="features-list" role="list">
                    <li><strong>Share Your Vision:</strong> From business insights to personal stories - if you can think it, we can voice it</li>
                    <li><strong>Choose Your Voice:</strong> Find the perfect AI voice to match your content's tone and style</li>
                    <li><strong>Shape Your Story:</strong> Guide the narrative or let our AI suggest compelling angles</li>
                    <li><strong>Share & Grow:</strong> Create a channel of bite-sized audio content that builds your authority</li>
                    <li className="special-features"><strong>Special Features:</strong></li>
                    <ul className="nested-features-list" role="list">
                      <li><strong>AI Insights:</strong> Get smart suggestions to enhance your story's impact</li>
                      <li><strong>Voice Match:</strong> Our AI pairs the perfect voice with your content style</li>
                      <li><strong>Time Machine:</strong> Bring historical perspectives to modern topics</li>
                      <li><strong>Your Audio Hub:</strong> Build a library of your audio insights and expertise</li>
                    </ul>
                  </ul>
                </nav>
              </article>
            </div>
            <div className="right-section">
              <section 
                className="hero-audio-player"
                aria-label="Audio player section"
              >
                {error ? (
                  <p className="error-message" role="alert">{error}</p>
                ) : audioFiles.length > 0 ? (
                  <FlexibleAudioPlayer tracks={audioFiles} />
                ) : (
                  <p role="status">Loading audio...</p>
                )}
              </section>
            </div>
          </div>
        </section>
      </main>
  
      <SignInModal isOpen={isModalOpen} onClose={closeModal} initialView="signup" />
  
      <footer className="home-footer">
        <p>Transform your ideas into polished audio content with Biglio.</p>
        <p>© Momentum Growth Partners, LLC</p>
      </footer>
    </div>
  );
};

export default HomePage;