import React from 'react';
import { Users } from 'lucide-react';

const TrendingChannels = () => {
  const channels = [
    {
      name: "Biglio Official",
      followers: "444K",
      url: "/channel/biglio",
      bgColor: "linear-gradient(135deg, #6B4DE6, #4299E1)"
    },
    {
      name: "Katie Hadley",
      followers: "182K",
      url: "/channel/katiehadley789",
      bgColor: "linear-gradient(135deg, #EC4899, #6B4DE6)"
    },
    {
      name: "Planet Zizzle",
      followers: "95K",
      url: "/channel/planetzizzle",
      bgColor: "linear-gradient(135deg, #48BB78, #4299E1)"
    },
    {
      name: "Brian Jon MC",
      followers: "67K",
      url: "/channel/brianjonmc",
      bgColor: "linear-gradient(135deg, #4299E1, #5A67D8)"
    }
  ];

  return (
    <div className="container">
      <div className="channels-grid">
        {channels.map((channel, index) => (
          <a
            key={index}
            href={channel.url}
            aria-label={`Visit ${channel.name} channel`}
          >
            <div className="channel-card">
              <div 
                className="channel-avatar"
                style={{ background: channel.bgColor }}
              />
              <div className="channel-info">
                <div className="channel-name">
                  <Users size={16} />
                  <span>{channel.name}</span>
                </div>
                <div className="channel-followers">
                  {channel.followers} followers
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default TrendingChannels;