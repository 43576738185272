import React, { useState, useEffect, useContext, useCallback, lazy, Suspense } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import ChannelHeader from './ChannelHeader';
import '../styles/ChannelPage.css';

const FlexibleAudioPlayer = lazy(() => import('./FlexibleAudioPlayer'));

const ITEMS_PER_PAGE = 6;

const ChannelPage = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useContext(AuthContext);
  const [audioFiles, setAudioFiles] = useState([]);
  const [displayedAudioFiles, setDisplayedAudioFiles] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [playlistName, setPlaylistName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [channelUser, setChannelUser] = useState(null);

  const isOwner = isAuthenticated && user?.username === username;

  const PUBLIC_API = 'https://utru71xp8h.execute-api.us-east-1.amazonaws.com/prod';
  const PRIVATE_API = 'https://r2qphapv7a.execute-api.us-east-1.amazonaws.com/prod';
  const PLAYLIST_API = 'https://qb3d9fwubc.execute-api.us-east-1.amazonaws.com/prod';

  const fetchChannelUser = useCallback(async () => {
    try {
      const response = await fetch(`${PUBLIC_API}/users/lookup?username=${username}`);
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('Channel not found');
        }
        throw new Error('Error looking up user');
      }
      const userData = await response.json();
      setChannelUser(userData);
      return userData.userid;
    } catch (err) {
      console.error('Error fetching channel user:', err);
      setError('Channel not found');
      return null;
    }
  }, [username]);

  const fetchUserAudioFiles = useCallback(async (userId) => {
    if (!userId) return;
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${PRIVATE_API}/audio-files-for-my-profile?userid=${userId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch audio files: ${response.status}`);
      }
      const data = await response.json();
      const sortedFiles = data
        .map(file => ({ ...file, isLoaded: false }))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setAudioFiles(sortedFiles);
      updateDisplayedFiles(sortedFiles, 1);
    } catch (error) {
      console.error('Error fetching audio files:', error);
      setError(`Failed to fetch audio files. ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPlaylists = useCallback(async (userId) => {
    if (!userId) return;
    try {
      const response = await fetch(`${PLAYLIST_API}/playlists?userid=${userId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch playlists: ${response.status}`);
      }
      const data = await response.json();
      setPlaylists(data.playlists || []);
    } catch (error) {
      console.error('Error fetching playlists:', error);
      setPlaylists([]);
    }
  }, []);

  useEffect(() => {
    const initializeChannel = async () => {
      setLoading(true);
      setError(null);
      try {
        let userId;
        if (isOwner && user?.userid) {
          userId = user.userid;
          setChannelUser({
            username: user.username,
            userid: user.userid,
            accessToken: user.accessToken
          });
        } else {
          userId = await fetchChannelUser();
        }
        if (userId) {
          await Promise.all([
            fetchUserAudioFiles(userId),
            fetchPlaylists(userId)
          ]);
        }
      } catch (err) {
        console.error('Error initializing channel:', err);
        setError('Failed to load channel');
      } finally {
        setLoading(false);
      }
    };
    if (username) {
      initializeChannel();
    }
  }, [username, isOwner, user, fetchChannelUser, fetchUserAudioFiles, fetchPlaylists]);

  const updateDisplayedFiles = (files, page, selectedPlaylist = playlistName) => {
    const filteredFiles = selectedPlaylist
      ? files.filter(file => file.playlistName === selectedPlaylist)
      : files;
    const startIndex = 0;
    const endIndex = page * ITEMS_PER_PAGE;
    const newDisplayedFiles = filteredFiles.slice(startIndex, endIndex).map(file => ({
      ...file,
      isLoaded: true,
      shareUrl: `/channel/${username}/audio/${file.id}`
    }));
    setDisplayedAudioFiles(newDisplayedFiles);
    setHasMore(endIndex < filteredFiles.length);
    setCurrentPage(page);
  };

  const loadMore = () => {
    const nextPage = currentPage + 1;
    updateDisplayedFiles(audioFiles, nextPage);
  };

  const handlePlaylistChange = (e) => {
    const newPlaylistName = e.target.value;
    setPlaylistName(newPlaylistName);
    setCurrentPage(1);
    updateDisplayedFiles(audioFiles, 1, newPlaylistName);
  };

  const renderAudioItem = (file) => {
    if (!file.isLoaded) {
      return <div className="audio-item loading">Loading audio...</div>;
    }
    return (
      <div className="audio-item">
        <div className="audio-player-wrapper">
          <Suspense fallback={<div>Loading audio player...</div>}>
            <FlexibleAudioPlayer tracks={[file]} />
          </Suspense>
        </div>
      </div>
    );
  };

  if (loading) {
    return <div className="channel-page-loading">Loading channel...</div>;
  }

  if (error) {
    return (
      <div className="channel-page-error">
        <p>{error}</p>
        <button 
          onClick={() => navigate('/')} 
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Return Home
        </button>
      </div>
    );
  }

  return (
    <div className="channel-page">
      <ChannelHeader 
        user={channelUser}
        playlists={playlists}
        onPlaylistChange={handlePlaylistChange}
        playlistName={playlistName}
        isOwner={isOwner}
        onError={(err) => {
          console.error('Profile error:', err);
          setError(err.message || 'An error occurred with the profile');
        }}
      />
      <main className="channel-content">
        <section>
          {loading && <div className="loading-spinner">Loading...</div>}
          {error && <p className="error-message">{error}</p>}
          {!loading && !error && displayedAudioFiles.length === 0 && (
            <p className="no-audio-message">
              {isOwner 
                ? "You haven't uploaded any audio files yet."
                : "This channel has no audio files yet."}
            </p>
          )}
          <div className="audio-library">
            {!loading && displayedAudioFiles.map((file) => (
              <React.Fragment key={file.id}>
                {renderAudioItem(file)}
              </React.Fragment>
            ))}
          </div>
          {hasMore && !loading && (
            <div className="load-more-container">
              <button onClick={loadMore} className="load-more-button">
                Load More
              </button>
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default ChannelPage;