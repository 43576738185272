import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import SignInModal from './components/SignInModal';
import CreatePage from './components/CreatePage';
import SearchPage from './components/SearchPage';
import MyProfile from './components/MyProfile';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import ForgotPassword from './components/ForgotPassword';
import AudioPlayerPage from './components/AudioPlayerPage';
import FlexibleAudioPlayer from './components/FlexibleAudioPlayer';
import { AuthProvider } from './context/AuthContext';
import { AudioProvider } from './context/AudioContext';
import AuthHandler from './components/AuthHandler';
import Voices from './components/Voices';
import PrivateRoute from './components/PrivateRoute';
import ChannelPage from './components/ChannelPage';

const App = () => {
  console.log('App component rendering');
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    console.log('Opening modal');
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    console.log('Closing modal');
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log('App component mounted');
  }, []);

  return (
    <AuthProvider>
      <AudioProvider>
        <Router>
          <div>
            <Navbar onSignInClick={openModal} onSignUpClick={openModal} />
            <Routes>
              <Route path="/" element={<HomePage openModal={openModal} />} />
              <Route path="/create" element={<CreatePage />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/channel/:username" element={<ChannelPage />} />
              <Route path="/channel/:username/audio/:audioId" element={<AudioPlayerPage />} />
              <Route path="/profile" element={<PrivateRoute><MyProfile /></PrivateRoute>} />
              <Route path="/signin" element={<SignIn onSignIn={closeModal} />} />
              <Route path="/signup" element={<SignUp onSignUp={closeModal} />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/audio-player" element={<AudioPlayerPage />} />
              <Route path="/a/:timestamp" element={<AudioPlayerPage />} /> 
              <Route path="/a/:timestamp" element={<AudioPlayerPage />} />
              <Route path="/voices" element={<Voices />} />
              <Route path="/flexible-audio-player" element={<FlexibleAudioPlayer />} />
            </Routes>
            <SignInModal isOpen={isModalOpen} onClose={closeModal} />
            <AuthHandler />
          </div>
        </Router>
      </AudioProvider>
    </AuthProvider>
  );
};

export default App;