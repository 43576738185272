import React, { useState, useContext } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { AuthContext } from '../context/AuthContext';
import awsConfig from '../aws-exports';
import '../styles/SignInModal.css';

const SignInModal = ({ isOpen, onClose, initialView = 'signin' }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [isSignUp, setIsSignUp] = useState(initialView === 'signup');
  const [isConfirming, setIsConfirming] = useState(false);
  const [mfaCode, setMfaCode] = useState('');
  const [isMfaRequired, setIsMfaRequired] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { setIsAuthenticated, refreshSession } = useContext(AuthContext);

  if (!isOpen) return null;

  const userPool = new CognitoUserPool({
    UserPoolId: awsConfig.userPoolId,
    ClientId: awsConfig.userPoolWebClientId,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isSignUp) {
      if (!termsAccepted) {
        setMessage('You must accept the Terms and Conditions to sign up');
        return;
      }

      const attributeList = [
        new CognitoUserAttribute({ Name: 'email', Value: email }),
      ];

      userPool.signUp(username, password, attributeList, null, (err, result) => {
        if (err) {
          setMessage(err.message || JSON.stringify(err));
          return;
        }
        setMessage('Sign up successful. Check your email for the confirmation code.');
        setIsConfirming(true);
      });
    } else {
      const user = new CognitoUser({ Username: username, Pool: userPool });
      const authDetails = new AuthenticationDetails({ Username: username, Password: password });

      user.authenticateUser(authDetails, {
        onSuccess: async (result) => {
          console.log('Sign in successful:', result);
          await refreshSession();
          setIsAuthenticated(true);
          onClose();
          setMessage('Sign in successful');
        },
        onFailure: (err) => {
          console.error('Sign in error:', err);
          setMessage(err.message || JSON.stringify(err));
        },
        mfaRequired: (codeDeliveryDetails) => {
          setIsMfaRequired(true);
        },
      });
    }
  };

  const handleConfirmSubmit = (event) => {
    event.preventDefault();

    const user = new CognitoUser({ Username: username, Pool: userPool });
    user.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        setMessage(err.message || JSON.stringify(err));
        return;
      }
      setMessage('Confirmation successful. You can now sign in.');
      setIsConfirming(false);
      setIsSignUp(false);
    });
  };

  const handleResendCode = () => {
    const user = new CognitoUser({ Username: username, Pool: userPool });
    user.resendConfirmationCode((err, result) => {
      if (err) {
        setMessage(err.message || JSON.stringify(err));
        return;
      }
      setMessage('A new confirmation code has been sent to your email.');
    });
  };

  const handleMfaSubmit = (event) => {
    event.preventDefault();

    const user = new CognitoUser({ Username: username, Pool: userPool });
    user.sendMFACode(mfaCode, {
      onSuccess: async (result) => {
        await refreshSession();
        setIsAuthenticated(true);
        onClose();
        setMessage('Sign in successful');
      },
      onFailure: (err) => {
        setMessage(err.message || JSON.stringify(err));
      },
    });
  };

  const handleForgotPasswordSubmit = (event) => {
    event.preventDefault();

    const user = new CognitoUser({ Username: username, Pool: userPool });
    user.forgotPassword({
      onSuccess: (data) => {
        console.log('Code sent:', data);
        setMessage('A verification code has been sent to your email.');
        setIsConfirming(true);
      },
      onFailure: (err) => {
        console.error('Error sending code:', err);
        setMessage(err.message || JSON.stringify(err));
      },
    });
  };

  const handleResetPasswordSubmit = (event) => {
    event.preventDefault();

    const user = new CognitoUser({ Username: username, Pool: userPool });
    user.confirmPassword(confirmationCode, password, {
      onSuccess: () => {
        console.log('Password reset successfully');
        setMessage('Password reset successfully. You can now sign in.');
        setIsForgotPassword(false);
        setIsConfirming(false);
      },
      onFailure: (err) => {
        console.error('Error resetting password:', err);
        setMessage(err.message || JSON.stringify(err));
      },
    });
  };

  const termsAndConditions = `
    Privacy Policy and Terms of Use
    Last Updated: October 25, 2024

    1. Introduction
    Welcome to Biglio ("Platform," "Site," or "Biglio"), operated by Momentum Growth Partners, LLC ("Company," "we," "our," or "us"). This policy explains how we handle your information and the terms governing your use of our audio content generation platform.

    2. Content Generation and Ownership
    By using our free service, you acknowledge and agree that:
    - All content generated through our platform is public
    - Momentum Growth Partners, LLC (operating as Biglio) owns all rights to all content (including audio files) generated through our platform
    - Audio files generated on Biglio cannot be used on any other platform, website, or service without explicit written permission from Momentum Growth Partners, LLC
    - Content may appear in public search results
    - Content may be shared by other users within the Biglio platform
    - We may use generated content for marketing and promotional purposes

    3. Information We Collect
    We collect:
    - Email address and basic profile information
    - Content generation history
    - Usage data and interactions
    - Browser type and device information
    - IP address and location data

    4. How We Use Your Information
    We use your information to:
    - Provide our service
    - Improve user experience
    - Maintain platform security
    - Send service updates
    - Analyze usage patterns

    5. Content Access and Sharing
    - All generated content is publicly accessible
    - Content appears in platform search results
    - Other users can view and share your content
    - Content may be featured on our platform
    - Content may be shared on social media

    6. User Responsibilities
    You agree to:
    - Provide accurate account information
    - Not generate harmful or illegal content
    - Not violate others' intellectual property rights
    - Not misuse the platform
    - Not attempt to circumvent platform limitations

    7. Platform Rights
    We reserve the right to:
    - Modify or terminate services
    - Remove content that violates our terms
    - Update these terms with notice
    - Limit platform access
    - Monitor content generation

    8. Security
    We implement reasonable security measures to protect your information but cannot guarantee absolute security.

    9. Third-Party Services
    We use third-party services for:
    - AI content generation
    - Analytics
    - Cloud storage
    - Payment processing (for premium features)

    10. Changes to Terms
    We may update these terms periodically. Continued use of the platform constitutes acceptance of any changes.

    11. Contact Us
    For questions about these terms, contact Momentum Growth Partners, LLC at [contact@biglio.com]

    12. AI Disclosure
    Our platform uses artificial intelligence to generate content. By using our service, you acknowledge that:
    - Content is AI-generated
    - Results may vary
    - We cannot guarantee specific outcomes
    - Generated content may require verification

    13. Data Retention
    We retain your account information and generated content for:
    - Platform functionality
    - Service improvement
    - Legal compliance
    - Historical reference

    14. Your Rights
    You have the right to:
    - Access your account information
    - Request account deletion
    - Report inappropriate content
    - Opt-out of marketing communications

    15. Limitations
    Free account limitations include:
    - All content is public
    - No private content options
    - No download capabilities
    - Standard generation limits
    - Basic feature access

    16. Termination
    We reserve the right to terminate accounts that:
    - Violate our terms
    - Engage in prohibited activities
    - Create harmful content
    - Misuse the platform
  `;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>{isSignUp ? 'Sign Up' : isForgotPassword ? 'Forgot Password' : 'Sign In'}</h2>
        {message && <div className="message">{message}</div>}
        {!isMfaRequired ? (
          !isConfirming ? (
            !isForgotPassword ? (
              <form onSubmit={handleSubmit}>
  <label htmlFor="username">
    Username: 
    {isSignUp && (
      <span style={{ fontSize: '0.875rem', color: '#4A5568', marginLeft: '0.5rem' }}>
        (this will be your channel name)
      </span>
    )}
  </label>
  <input
    type="text"
    id="username"
    name="username"
    value={username}
    onChange={(e) => setUsername(e.target.value)}
    required
  />
  <label htmlFor="password">Password:</label>
  <input
    type="password"
    id="password"
    name="password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    required
  />
  {isSignUp && (
    <>
      <p className="password-requirements">
        Password must be at least 8 characters long and include a mix of uppercase, lowercase, numbers, and special characters.
      </p>
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <div className="terms-container" style={{ 
        border: '1px solid var(--border-purple)',
        borderRadius: '5px',
        padding: '10px',
        marginBottom: '1rem',
        height: '150px',
        overflowY: 'auto'
      }}>
        <pre style={{ 
          fontSize: '0.75rem',
          whiteSpace: 'pre-wrap',
          fontFamily: 'Arial, sans-serif'
        }}>
          {termsAndConditions}
        </pre>
      </div>
      <label className="terms-checkbox" style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem'
      }}>
        <input
          type="checkbox"
          checked={termsAccepted}
          onChange={(e) => setTermsAccepted(e.target.checked)}
          required
          style={{ marginRight: '8px', width: 'auto' }}
        />
        <span style={{ fontSize: '0.875rem', color: 'var(--text-medium)' }}>
          I have read and agree to the Terms and Conditions
        </span>
      </label>
    </>
  )}
  <button type="submit">{isSignUp ? 'Sign Up' : 'Sign In'}</button>
</form>
            ) : (
              <form onSubmit={handleForgotPasswordSubmit}>
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <button type="submit">Send Verification Code</button>
              </form>
            )
          ) : (
            <form onSubmit={isForgotPassword ? handleResetPasswordSubmit : handleConfirmSubmit}>
              <label htmlFor="confirmationCode">Confirmation Code:</label>
              <input
                type="text"
                id="confirmationCode"
                name="confirmationCode"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                required
              />
              {isForgotPassword && (
                <>
                  <label htmlFor="password">New Password:</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </>
              )}
              <button type="submit">{isForgotPassword ? 'Reset Password' : 'Confirm'}</button>
              {!isForgotPassword && <button type="button" onClick={handleResendCode}>Resend Code</button>}
            </form>
          )
        ) : (
          <form onSubmit={handleMfaSubmit}>
            <label htmlFor="mfaCode">MFA Code:</label>
            <input
              type="text"
              id="mfaCode"
              name="mfaCode"
              value={mfaCode}
              onChange={(e) => setMfaCode(e.target.value)}
              required
            />
            <button type="submit">Submit MFA Code</button>
          </form>
        )}
        {!isForgotPassword && (
          <div className="sign-up-link" onClick={() => setIsSignUp(!isSignUp)}>
            {isSignUp ? 'Already have an account? Sign In' : 'Don\'t have an account? Sign Up'}
          </div>
        )}
        {!isSignUp && !isForgotPassword && (
          <div className="sign-up-link" onClick={() => setIsForgotPassword(true)}>
            Forgot your password? Reset it here
          </div>
        )}
      </div>
    </div>
  );
};

export default SignInModal;