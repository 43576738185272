import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import TrendingChannels from './TrendingChannels';
import '../styles/TrendingChannels.css';
import '../styles/SearchPageV2.css';
import '../styles/FlexibleAudioPlayerV2.css';

const FlexibleAudioPlayer = lazy(() => import('./FlexibleAudioPlayer'));

const ITEMS_PER_PAGE = 6;

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      {isVisible && 
        <div onClick={scrollToTop} className="scroll-to-top">
          ↑
        </div>
      }
    </>
  );
};

function SearchPage() {
  const [displayedFiles, setDisplayedFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecentFiles = async () => {
      setLoading(true);
      setError(null);
      try {
        let url = new URL('https://oovmxcx0n0.execute-api.us-east-1.amazonaws.com/v1/audio-categories');
        const params = new URLSearchParams();
        
        params.append('limit', ITEMS_PER_PAGE.toString());
        params.append('type', 'recent');
        
        url.search = params.toString();
        
        console.log('Fetching from URL:', url.toString());
  
        const response = await fetch(url.toString(), {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to fetch recent files: ${response.status}. ${errorText}`);
        }
  
        const data = await response.json();
        console.log('Received data:', data);
        
        const newFiles = data.items.map(file => ({
          ...file,
          audioUrl: file.audioUrl && !file.audioUrl.startsWith('http') ? `https://${file.audioUrl}` : file.audioUrl,
          isLoaded: true
        }));
  
        setDisplayedFiles(newFiles);
        
      } catch (err) {
        console.error('Error details:', err);
        setError(`Failed to fetch recent files. ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentFiles();
  }, []);

  const renderAudioItem = (file) => {
    console.log('File to render:', file);
  
    return (
      <div className="audio-item" key={file.id || file.metadataId}>
        <div className="audio-player-wrapper">
          {file.audioUrl ? (
            <Suspense fallback={<div>Loading audio player...</div>}>
              <FlexibleAudioPlayer 
                tracks={[{
                  id: file.id || file.metadataId,
                  audioUrl: file.audioUrl && !file.audioUrl.startsWith('http') 
                    ? `https://${file.audioUrl}` 
                    : file.audioUrl,
                  topic: file.topic || 'Untitled',
                  summary: file.summary || '', // Changed from specificFocus to summary
                  username: file.username,
                  length: file.length || '0',
                }]} 
              />
            </Suspense>
          ) : (
            <div className="error-message">
              Audio file not available for: {file.topic || 'Untitled'}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="search-page">
      <Helmet>
        <title>Trending Audio Content - Your Site Name</title>
        <meta name="description" content="Discover trending audio content and channels." />
      </Helmet>
      <h1>Listen to what's trending</h1>
      
      <div className="channels-section">
        <h2 className="section-header">TRENDING CHANNELS:</h2>
        <TrendingChannels />
      </div>

      <div className="content-section">
        <h2 className="section-header">TRENDING CONTENT:</h2>
      
        {error && <p className="error-message">{error}</p>}
        {loading && <div className="loading-spinner">Loading...</div>}
        <div className="audio-library">
          {displayedFiles.map(renderAudioItem)}
        </div>
        {!loading && displayedFiles.length === 0 && (
          <p className="no-results">No audio files found.</p>
        )}
      </div>
      
      <ScrollToTop />
    </div>
  );
}

export default SearchPage;