import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Voices from './Voices';
import SignInModal from './SignInModal';
import ReCAPTCHA from 'react-google-recaptcha';
import '../styles/CreatePageV11.css';
import { checkContent, SpicyOverlay } from '../utils/moderation';

// Your existing categories array
const categories = [
  'Technology', 'Health', 'Science', 'Business', 
  'Entertainment', 'Sports', 'Travel', 'History', 'Children\'s Story',
  'Short Story', 'Time Machine', 'Recipes', 'Other'
];

// Your existing getTimeMachineInstructions function
const getTimeMachineInstructions = () => {
  return {
    title: "Time Machine Format",
    examples: [
      "Speak as Albert Einstein discussing artificial intelligence",
      "Speak as Leonardo da Vinci examining modern technology",
      "Speak as Abraham Lincoln addressing current political divisions",
      "Speak as Marie Curie discussing women in modern science"
    ],
    format: "Speak as [Historical Figure] discussing/examining/addressing [Topic]. Include references to their own time period and experiences."
  };
};

// Your existing VoicePreview component
const VoicePreview = ({ voice, onSelect }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio] = useState(new Audio());

  const sampleAudioUrls = {
    Andrew: "https://storage-audio-file.s3.us-east-1.amazonaws.com/audio/voice-samples/voice-sample-andrew.mp3",
    Ava: "https://storage-audio-file.s3.us-east-1.amazonaws.com/audio/voice-samples/voice-sample-ava.mp3",
    Ana: "https://storage-audio-file.s3.us-east-1.amazonaws.com/audio/voice-samples/voice-sample-ana.mp3",
    Ryan: "https://storage-audio-file.s3.us-east-1.amazonaws.com/audio/voice-samples/voice-sample-ryan.mp3"
  };

  const handlePreview = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isPlaying) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(false);
    } else {
      audio.src = sampleAudioUrls[voice];
      audio.play().catch(error => {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
      });
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio]);

  useEffect(() => {
    const handleEnded = () => setIsPlaying(false);
    audio.addEventListener('ended', handleEnded);
    return () => audio.removeEventListener('ended', handleEnded);
  }, [audio]);

  return (
    <div className="button-group">
      <button 
        onClick={handlePreview}
        className="preview-button"
        type="button"
      >
        {isPlaying ? '⏹️  Stop   ' : '▶️ Preview'}
      </button>
      <button 
        onClick={() => onSelect(voice)}
        className="choose-button"
        type="button"
      >
        Choose
      </button>
    </div>
  );
};

function CreatePage() {
  const navigate = useNavigate();
  const { isAuthenticated, user, isLoading, refreshSession } = useContext(AuthContext);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const recaptchaRef = useRef(null);  // Add this line

  const [topic, setTopic] = useState('');
  const [category, setCategory] = useState('');
  const [specificFocus, setSpecificFocus] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('');
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [error, setError] = useState(null);
  const [contentReady, setContentReady] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [playlistName, setPlaylistName] = useState('');
  const [showVoices, setShowVoices] = useState(false);
  const [progress, setProgress] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [autoSelectedVoice, setAutoSelectedVoice] = useState(false);
  const [showSpicyOverlay, setShowSpicyOverlay] = useState(false);
  const [spicyMessage, setSpicyMessage] = useState('');


  const availableVoices = [
    { id: 'Andrew', name: 'Andrew', description: 'Adult Male', avatar: '👨' },
    { id: 'Ava', name: 'Ava', description: 'Adult Female', avatar: '👩' },
    { id: 'Ryan', name: 'Ryan', description: 'British Male', avatar: '👨' },
    { id: 'Ana', name: 'Ana', description: 'Child Female', avatar: '👧' }
  ];

  const suggestVoice = (selectedCategory) => {
    switch(selectedCategory) {
      case 'Children\'s Story':
        setSelectedVoice('Ana');
        break;
      case 'Business':
      case 'Technology':
        setSelectedVoice('Andrew');
        break;
      default:
        setSelectedVoice('Ava');
    }
    setAutoSelectedVoice(true);
  };

  useEffect(() => {
    const refreshUserSession = async () => {
      try {
        await refreshSession();
      } catch (err) {
        console.error('Error refreshing session:', err);
      }
    };

    refreshUserSession();

    const fetchPlaylists = async () => {
      if (user) {
        try {
          const userSub = user.userid;
          const response = await fetch(`https://qb3d9fwubc.execute-api.us-east-1.amazonaws.com/prod/playlists?userid=${userSub}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch playlists: ${response.status}`);
          }

          const data = await response.json();
          setPlaylists(data.playlists || []);
        } catch (error) {
          console.error('Error fetching playlists:', error);
          setError('Failed to fetch playlists');
        }
      }
    };

    if (isAuthenticated) {
      fetchPlaylists();
    }
  }, [refreshSession, user, isAuthenticated]);

  const handleCategoryChange = (e) => {
    if (!isAuthenticated) return;

    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    suggestVoice(selectedCategory);
  };

  const handleGetSuggestions = async () => {
    if (!isAuthenticated) return;

    setError(null);
    setLoadingSuggestions(true);

    try {
      const response = await fetch('https://2uhtkxnckk.execute-api.us-east-1.amazonaws.com/prod/generate-suggestions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ topic, category }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSuggestions(data.suggestions || []);
    } catch (err) {
      console.error('Error fetching suggestions:', err);
      setError(`An error occurred while fetching suggestions: ${err.message}`);
    } finally {
      setLoadingSuggestions(false);
    }
  };

  const handleUseSuggestion = (suggestion) => {
    setSelectedSuggestions((prevSelected) => {
      if (prevSelected.includes(suggestion)) {
        const newSelections = prevSelected.filter((s) => s !== suggestion);
        setSpecificFocus((prevFocus) => {
          const updatedFocus = prevFocus.replace(suggestion, '').trim();
          return updatedFocus;
        });
        return newSelections;
      } else {
        const newSelections = [...prevSelected, suggestion];
        setSpecificFocus((prevFocus) => {
          const prefix = prevFocus ? prevFocus + ' ' : '';
          return prefix + suggestion;
        });
        return newSelections;
      }
    });
  };

  const handleGenerate = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) return;
  
    const contentCheck = checkContent(topic, specificFocus);
    if (!contentCheck.isValid) {
      setSpicyMessage(contentCheck.message);
      setShowSpicyOverlay(true);
      return;
    }  

    try {
      // Execute reCAPTCHA and get token
      const token = await recaptchaRef.current.execute();
      
      setLoadingGenerate(true);
      setError(null);

      const userSub = user.userid;
      if (!userSub) {
        throw new Error('User ID not available. Please log in again.');
      }
      
      const response = await fetch('https://wq0vbglp42.execute-api.us-east-1.amazonaws.com/prod/create-audio', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          topic,
          category,
          specificFocus,
          selectedVoice,
          playlistName,
          suggestions: selectedSuggestions,
          userid: userSub,
          username: user.username,
          recaptchaToken: token  // Add the token to the request
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setContentReady(true);
    } catch (err) {
      console.error('Error generating audio:', err);
      setError(`An error occurred while generating audio: ${err.message}`);
      recaptchaRef.current.reset();  // Reset reCAPTCHA on error
    } finally {
      setLoadingGenerate(false);
    }
  };

  const handleNavigateToProfile = () => {
    navigate(`/channel/${user.username}`);
  };

  return (
    <div className="create-page">
      <div className="content">
        <h1>Create Your Audio</h1>
        {!isAuthenticated && (
          <button 
            className="auth-message-box" 
            onClick={() => setShowSignInModal(true)}
            type="button"
          >
            Please sign in to create content.
          </button>
        )}
        
        <SignInModal 
          isOpen={showSignInModal} 
          onClose={() => setShowSignInModal(false)}
          initialView="signin"
        />
      
        <form className="create-form" onSubmit={handleGenerate}>
          <div className="form-group">
            <label htmlFor="topic">Topic</label>
            <input
              type="text"
              id="topic"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              placeholder="Enter the topic of your audio"
              required
              disabled={!isAuthenticated}
            />
          </div>

          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              id="category"
              value={category}
              onChange={handleCategoryChange}
              required
              disabled={!isAuthenticated}
            >
              <option value="" disabled>Select a category</option>
              {categories.map((cat, index) => (
                <option key={index} value={cat}>{cat}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="specificFocus">What should be included in your audio?</label>
            {category === 'Time Machine' && (
              <div className="time-machine-instructions">
                <h4>Time Machine Format Guide</h4>
                <p>Format your request as: "{getTimeMachineInstructions().format}"</p>
                <div className="examples-section">
                  <h5>Examples:</h5>
                  <ul>
                    {getTimeMachineInstructions().examples.map((example, index) => (
                      <li key={index} onClick={() => setSpecificFocus(example)} style={{ cursor: 'pointer' }}>
                        {example}
                      </li>
                    ))}
                  </ul>
                </div>
                <p className="tip">Click any example to use it as a template</p>
              </div>
            )}
            <button 
              type="button" 
              onClick={handleGetSuggestions} 
              className="button get-suggestions-button" 
              disabled={loadingSuggestions || category === 'Time Machine' || !isAuthenticated}
            >
              {loadingSuggestions ? 'Fetching Suggestions...' : 'Get Suggestions'}
            </button>
            {suggestions.length > 0 && (
              <div className="suggestions-container">
                {suggestions.map((suggestion, index) => (
                  <div key={index} className="suggestion-item">
                    <p>{suggestion}</p>
                    <button
                      type="button"
                      onClick={() => handleUseSuggestion(suggestion)}
                      className={`button use-suggestion-button ${selectedSuggestions.includes(suggestion) ? 'selected' : ''}`}
                      disabled={!isAuthenticated}
                    >
                      {selectedSuggestions.includes(suggestion) ? 'Unselect' : 'Use This'}
                    </button>
                  </div>
                ))}
              </div>
            )}
            <textarea
              id="specificFocus"
              value={specificFocus}
              onChange={(e) => setSpecificFocus(e.target.value)}
              placeholder="Add specific details about what you want included or excluded"
              maxLength={300}
              required
              disabled={!isAuthenticated}
            />
            <p className="char-count">{300 - specificFocus.length} characters remaining</p>
          </div>

          <div className="form-group">
            <label>Choose a voice</label>
            <div className="voice-options">
              {availableVoices.map(voice => (
                <div 
                  className={`voice-card ${selectedVoice === voice.id ? 'selected' : ''}`} 
                  key={voice.id}
                >
                  <div className="voice-info">
                    <div className="voice-avatar">{voice.avatar}</div>
                    <strong>{voice.name}</strong>
                  </div>
                  <VoicePreview 
                    voice={voice.id}
                    onSelect={(voiceId) => setSelectedVoice(voiceId)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="form-group playlist-group">
            <label htmlFor="playlist">Add to playlist (optional)</label>
            <div className="playlist-controls">
              <div className="half-width">
                <select
                  id="playlist"
                  value={playlistName}
                  onChange={(e) => setPlaylistName(e.value)}
                  disabled={!isAuthenticated}
                >
                  <option value="" disabled>Select existing playlist</option>
                  {playlists.length > 0 ? (
                    playlists.map((playlist, index) => (
                      <option key={index} value={playlist}>
                        {playlist}
                      </option>
                    ))
                  ) : (
                    <option value="">No Playlists Yet</option>
                  )}
                </select>
              </div>
              <div className="half-width">
                <input
                  type="text"
                  id="newPlaylistName"
                  value={playlistName}
                  onChange={(e) => setPlaylistName(e.target.value)}
                  placeholder="Or enter a new playlist name"
                  disabled={!isAuthenticated}
                />
              </div>
            </div>
          </div>

          {/* Add reCAPTCHA before the submit button */}
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey="6LcMBXwqAAAAACVROmNc2y98C4C30_qu4JQxORYI"
          />

          <button 
            type="submit" 
            className="button generate-button" 
            disabled={loadingGenerate || isLoading || !isAuthenticated}
          >
            {loadingGenerate ? 'Generating...' : 'Create Audio'}
          </button>
        </form>

        {error && <p className="error">{error}</p>}
        
        {loadingGenerate && !contentReady && (
          <div className="animation-container">
            <div className="flex flex-col items-center justify-center p-8">
              <div className="animate-spin rounded-full h-32 w-32 border-8 border-purple-600 border-t-transparent mb-4">
                <div className="h-4 w-4 bg-purple-600 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
              </div>
              <div className="text-center">
                <p className="text-lg font-medium text-purple-600">Creating your audio</p>
                <p className="text-sm text-gray-500">This will take less than a minute</p>
              </div>
            </div>
          </div>
        )}
        
        {contentReady && (
          <button className="button content-ready-button" onClick={handleNavigateToProfile}>
            ALL DONE! CLICK HERE TO LISTEN
          </button>
        )}
      </div>
      
      {showSignInModal && (
        <SignInModal onClose={() => setShowSignInModal(false)} />
      )}
      
      {showVoices && (
        <div className="voices-overlay">
          <Voices onClose={() => setShowVoices(false)} />
        </div>
      )}
      {showSpicyOverlay && (
  <SpicyOverlay 
    message={spicyMessage}
    onClose={() => setShowSpicyOverlay(false)}
  />
)}
    </div>
  );
}

export default CreatePage;