import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import '../styles/Navbar.css';

const Navbar = ({ onSignInClick, onSignUpClick }) => {
  const { isAuthenticated, signOut, user } = useContext(AuthContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Update username when user data becomes available
    if (user) {
      // Check different possible locations of username based on your Cognito setup
      const userUsername = user.username || // Direct username property
                          user.attributes?.username || // Username in attributes
                          user.signInUserSession?.idToken?.payload?.username || // Username in token
                          user.signInUserSession?.idToken?.payload['cognito:username']; // Cognito specific username
      
      if (userUsername) {
        setUsername(userUsername);
      } else {
        console.error('Unable to find username in user object:', user);
      }
    }
  }, [user]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleSignOut = () => {
    signOut();
    closeMobileMenu();
    navigate('/');
  };

  const handleMyChannelClick = (e) => {
    if (!username) {
      e.preventDefault();
      console.error('Username not available');
      // Optionally show a message to the user
      return;
    }
    closeMobileMenu();
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/" onClick={closeMobileMenu}>Biglio</Link>
      </div>
      <div className="navbar-toggle" onClick={toggleMobileMenu}>
        <span className="navbar-toggle-icon">&#9776;</span>
      </div>
      <ul className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}>
        <li><Link to="/" onClick={closeMobileMenu}>HOME</Link></li>
        <li><Link to="/search" onClick={closeMobileMenu}>DISCOVER</Link></li> {/* Updated text */}
        <li><Link to="/create" onClick={closeMobileMenu}>CREATE</Link></li>
        {isAuthenticated && username && (
          <>
            <li>
              <Link 
                to={`/channel/${username}`} 
                onClick={handleMyChannelClick}
              >
                MY CHANNEL
              </Link>
            </li>
            <li><button onClick={handleSignOut}>SIGN OUT</button></li>
          </>
        )}
        {!isAuthenticated && (
          <li><button onClick={() => { onSignInClick(); closeMobileMenu(); }}>SIGN IN</button></li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;