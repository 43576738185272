import React from 'react';
import FlexibleAudioPlayer from './FlexibleAudioPlayer'; // Updated import
import '../styles/Voices.css';
import '../styles/FlexibleAudioPlayerV2.css';

const Voices = ({ onClose }) => { // Capitalized component name
  const voiceSamples = [
    { audioUrl: 'https://storage-audio-file.s3.amazonaws.com/audio/1729113070722-en-US-GuyNeural.mp3', title: 'Guy', category: 'Other' },
    { audioUrl: 'https://storage-audio-file.s3.amazonaws.com/audio/1729113238462-en-US-JennyNeural.mp3', title: 'Jenny', category: 'Other' },
    { audioUrl: 'https://storage-audio-file.s3.amazonaws.com/audio/1729113337622-en-US-AriaNeural.mp3', title: 'Aria', category: 'Other' },
    { audioUrl: 'https://storage-audio-file.s3.amazonaws.com/audio/1729113440325-en-US-DavisNeural.mp3', title: 'Davis', category: 'Other' },
    { audioUrl: 'https://storage-audio-file.s3.amazonaws.com/audio/1729113545249-en-GB-RyanNeural.mp3', title: 'Ryan', category: 'Other' },
    { audioUrl: 'https://storage-audio-file.s3.amazonaws.com/audio/1729113611852-en-US-NancyNeural.mp3', title: 'Nancy', category: 'Other' },
    { audioUrl: 'https://storage-audio-file.s3.amazonaws.com/audio/1729113689161-en-US-SaraNeural.mp3', title: 'Sara', category: 'Other' },
    { audioUrl: 'https://storage-audio-file.s3.amazonaws.com/audio/1729113760350-en-US-GuyNeural.mp3', title: 'Tony', category: 'Other' },
    { audioUrl: 'https://storage-audio-file.s3.amazonaws.com/audio/1729113860281-en-US-AnaNeural.mp3', title: 'Ana', category: 'Other' },
  ];

  return (
    <div className="voices-popup">
      <button onClick={onClose} className="close-button">X</button>
      <h2>Biglio Voices</h2>
      <div className="voices-content">
        {voiceSamples.map((sample, index) => (
          <FlexibleAudioPlayer // Updated component usage
            key={index}
            audioUrl={sample.audioUrl}
            title={sample.title}
            category={sample.category}
          />
        ))}
      </div>
    </div>
  );
};

export default Voices; // Updated export to match component name