import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Printer } from 'lucide-react';
import '../styles/QRCodeModal.css';

const QRCodeModal = ({ url, onClose }) => {
  return (
    <div className="qr-modal-overlay">
  <div 
    className="qr-modal-backdrop"
    onClick={onClose}
  />
  <div className="qr-modal-container">
    <div className="qr-modal-content">
      <div className="qr-modal-header">
        <h3 className="qr-modal-title">
          Share QR Code
        </h3>
        <button 
          onClick={onClose}
          className="qr-modal-close"
        >
          ✕
        </button>
      </div>
      
      <div id="printArea" className="qr-code-container">
        <QRCodeSVG 
          value={url}
          size={200}
          level="H"
          includeMargin={true}
          bgColor="#FFFFFF"
          fgColor="#000000"
        />
      </div>
        
      <button
        onClick={() => window.print()}
        className="qr-print-button"
      >
        <Printer size={20} />
        Print QR Code
      </button>
    </div>
  </div>
</div>
  );
};

export default QRCodeModal;