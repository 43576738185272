import React from 'react';
import '../styles/moderation.css';

const BANNED_WORDS = [
  'sex', 'porn', 'xxx', 'nude', 'naked',
  'cocaine', 'heroin', 'drugs', 'weed',
  'terrorist', 'bomb', 'kill', 'death',
];

const FUNNY_REJECTIONS = [
  "Whoops! That's a bit spicy for our taste! Let's keep it PG. 😇",
  "Nice try, but we don't play that way! How about something more family-friendly? 🌟",
  "Sorry friend, we're keeping things clean around here! 😎",
  "Sorry not sorry, you have the wrong site! 😎",
  "It's not me it's you ... we are not that kind of site! 😎",
  "That's a no-go zone! Let's stick to wholesome content. ✨",
];

export const SpicyOverlay = ({ message, onClose }) => (
  <div className="spicy-overlay">
    <div className="spicy-content">
      <div className="spicy-message">{message}</div>
      <button 
        className="behave-button"
        onClick={onClose}
      >
        I Promise to Behave 😇
      </button>
    </div>
  </div>
);

export const checkContent = (topic, specificFocus) => {
  const combinedText = `${topic} ${specificFocus}`.toLowerCase();
  
  const foundBannedWord = BANNED_WORDS.find(word => 
    combinedText.includes(word.toLowerCase())
  );

  if (foundBannedWord) {
    const rejectMessage = FUNNY_REJECTIONS[
      Math.floor(Math.random() * FUNNY_REJECTIONS.length)
    ];
    
    return {
      isValid: false,
      message: rejectMessage
    };
  }

  return {
    isValid: true,
    message: null
  };
};