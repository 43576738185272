// src/components/MyProfile.js
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import FlexibleAudioPlayer from './FlexibleAudioPlayer';
import ChannelHeader from './ChannelHeader';
import '../styles/MyProfile.css';

const ITEMS_PER_PAGE = 6;

const MyProfile = () => {
  const { isAuthenticated, user, isLoading, refreshSession } = useContext(AuthContext);
  const [audioFiles, setAudioFiles] = useState([]);
  const [displayedAudioFiles, setDisplayedAudioFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userSub, setUserSub] = useState(null);
  const [playlists, setPlaylists] = useState([]);
  const [playlistName, setPlaylistName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const refreshUserSession = async () => {
      try {
        await refreshSession();
      } catch (err) {
        console.error('Error refreshing session:', err);
        setError('Failed to refresh session. Please sign in again.');
      }
    };

    refreshUserSession();
  }, [refreshSession]);

  useEffect(() => {
    if (user && user.idToken) {
      try {
        let sub;
        if (typeof user.idToken === 'string') {
          const payload = JSON.parse(atob(user.idToken.split('.')[1]));
          sub = payload.sub;
        } else if (user.idToken.payload && user.idToken.payload.sub) {
          sub = user.idToken.payload.sub;
        } else if (user.userid) {
          sub = user.userid;
        } else {
          throw new Error('Unable to extract user ID');
        }
        setUserSub(sub);
      } catch (err) {
        console.error('Error processing user information:', err);
        setError('Failed to get user information. Please sign in again.');
      }
    }
  }, [user]);

  const fetchUserAudioFiles = useCallback(async () => {
    if (isAuthenticated && userSub) {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `https://r2qphapv7a.execute-api.us-east-1.amazonaws.com/prod/audio-files-for-my-profile?userid=${userSub}`
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch audio files: ${response.status}`);
        }
        const data = await response.json();
        const sortedFiles = data.map(file => ({ ...file, isLoaded: false }))
                               .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setAudioFiles(sortedFiles);
        updateDisplayedFiles(sortedFiles, 1);
      } catch (error) {
        console.error('Error fetching user audio files:', error);
        setError(`Failed to fetch audio files. ${error.message}`);
      } finally {
        setLoading(false);
      }
    }
  }, [isAuthenticated, userSub]);

  const updateDisplayedFiles = (files, page, selectedPlaylist = playlistName) => {
    const filteredFiles = selectedPlaylist
      ? files.filter(file => file.playlistName === selectedPlaylist)
      : files;

    const startIndex = 0;
    const endIndex = page * ITEMS_PER_PAGE;
    const newDisplayedFiles = filteredFiles.slice(startIndex, endIndex).map(file => ({
      ...file,
      isLoaded: true
    }));

    setDisplayedAudioFiles(newDisplayedFiles);
    setHasMore(endIndex < filteredFiles.length);
    setCurrentPage(page);
  };

  const loadMore = () => {
    const nextPage = currentPage + 1;
    updateDisplayedFiles(audioFiles, nextPage);
  };

  const handlePlaylistChange = (e) => {
    const newPlaylistName = e.target.value;
    setPlaylistName(newPlaylistName);
    setCurrentPage(1);
    updateDisplayedFiles(audioFiles, 1, newPlaylistName);
  };

  const fetchPlaylists = useCallback(async () => {
    if (isAuthenticated && userSub) {
      try {
        const response = await fetch(`https://qb3d9fwubc.execute-api.us-east-1.amazonaws.com/prod/playlists?userid=${userSub}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch playlists: ${response.status}`);
        }

        const data = await response.json();
        setPlaylists(data.playlists || []);
      } catch (error) {
        console.error('Error fetching playlists:', error);
        setError('Failed to fetch playlists');
      }
    }
  }, [isAuthenticated, userSub]);

  useEffect(() => {
    fetchUserAudioFiles();
    fetchPlaylists();
  }, [fetchUserAudioFiles, fetchPlaylists]);

  const renderAudioItem = (file) => {
    if (!file.isLoaded) {
      return <div className="audio-item loading">Loading audio...</div>;
    }
    return (
      <div className="audio-item">
        <div className="audio-player-wrapper">
          <FlexibleAudioPlayer tracks={[file]} />
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <div>Loading user information...</div>;
  }

  return (
    <div className="profile-container">
      {!isAuthenticated && (
        <div className="overlay">
          <p>Please log in to view your profile.</p>
        </div>
      )}
      <div className={`content ${!isAuthenticated ? 'blurred' : ''}`}>
        <ChannelHeader 
          user={user}
          playlists={playlists}
          onPlaylistChange={handlePlaylistChange}
          playlistName={playlistName}
        />
        
        <main className="profile-main">
          <section>
            {loading && <div className="loading-spinner">Loading...</div>}
            {error && <p className="error-message">{error}</p>}
            {!loading && !error && displayedAudioFiles.length === 0 && (
              <p className="no-audio-message">No audio files found.</p>
            )}
            <div className="audio-library">
              {!loading && displayedAudioFiles.map((file) => (
                <React.Fragment key={file.id}>
                  {renderAudioItem(file)}
                </React.Fragment>
              ))}
            </div>
            {hasMore && !loading && (
              <div className="load-more-container">
                <button onClick={loadMore} className="load-more-button">
                  Load More
                </button>
              </div>
            )}
          </section>
        </main>
      </div>
    </div>
  );
};

export default MyProfile;